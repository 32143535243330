import {
  CCol,
  CFormInput,
  CModalBody,
  CModalFooter,
  CRow,
} from '@coreui/react-pro'
import Layout from './Layout'
import SModal from '@/components/shared/Modal/SModal'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { SERVER_API_URL } from '@/shared/config/constants'
import { IGame } from '@/shared/model/game.model'
import {
  ToastErrorBottom,
  ToastSuccessBottom,
} from '@/components/shared/toast/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import dayjs from 'dayjs'
import gif1 from '../../../assets/img/gif1.gif'

import phanphoi from '../../../assets/img/newimg/phanphoi.png'
import khachhang from '../../../assets/img/newimg/khachhang.png'
import sanpham from '../../../assets/img/newimg/sanpham.png'
import thoitrang from '../../../assets/img/newimg/thoitrang.png'

import quanao from '../../../assets/img/newimg/quanao.png'
import tuixach from '../../../assets/img/newimg/tuixach.png'
import giaydep from '../../../assets/img/newimg/giaydep.png'
import phukien from '../../../assets/img/newimg/phukien.png'
import nuochoa from '../../../assets/img/newimg/nuochoa.png'

import ketqua from '../../../assets/img/newimg/ketqua.png'
import nleft from '../../../assets/img/newimg/boxchon.png'
import logotop from '../../../assets/img/newimg/bg-home-1.jpeg'
import KyQuay1 from './KyQuay1'

import challimage1 from '../../../assets/img/newimg/challimg/1.jpg'
import challimage2 from '../../../assets/img/newimg/challimg/2.jpg'
import challimage3 from '../../../assets/img/newimg/challimg/3.jpg'
import challimage4 from '../../../assets/img/newimg/challimg/4.jpg'
import challimage5 from '../../../assets/img/newimg/challimg/5.jpg'
import challimage6 from '../../../assets/img/newimg/challimg/6.jpg'
import challimage7 from '../../../assets/img/newimg/challimg/7.jpg'
import challimage8 from '../../../assets/img/newimg/challimg/8.jpg'
import challimage9 from '../../../assets/img/newimg/challimg/9.jpg'
import challimage10 from '../../../assets/img/newimg/challimg/10.jpg'
import challimage11 from '../../../assets/img/newimg/challimg/11.jpg'
import challimage12 from '../../../assets/img/newimg/challimg/12.jpg'
import challimage13 from '../../../assets/img/newimg/challimg/13.jpg'
import challimage14 from '../../../assets/img/newimg/challimg/14.jpg'

import chall1 from '../../../assets/img/newimg/challimg/img/1.jpg'
import chall2 from '../../../assets/img/newimg/challimg/img/2.jpg'
import chall3 from '../../../assets/img/newimg/challimg/img/3.jpg'
import chall4 from '../../../assets/img/newimg/challimg/img/4.jpg'
import chall5 from '../../../assets/img/newimg/challimg/img/5.svg'
import chall6 from '../../../assets/img/newimg/challimg/img/6.jpg'
import chall7 from '../../../assets/img/newimg/challimg/img/7.svg'
import chall8 from '../../../assets/img/newimg/challimg/img/8.jpg'
import chall9 from '../../../assets/img/newimg/challimg/img/9.jpg'
import chall10 from '../../../assets/img/newimg/challimg/img/10.jpg'
import chall11 from '../../../assets/img/newimg/challimg/img/11.svg'
import chall12 from '../../../assets/img/newimg/challimg/img/12.jpg'
import chall13 from '../../../assets/img/newimg/challimg/img/13.jpg'
import chall14 from '../../../assets/img/newimg/challimg/img/14.jpg'

import img123 from '../../../assets/img/newimg/img123.png'

import logoBG from '../../../assets/img/newimg/logo-bg.png'

import Marquee from 'react-fast-marquee'
import { object } from 'yup/lib/locale'

const dataii2 = [
  {
    name: 'Etro',
    image: chall1,
  },
  {
    name: 'Fay',
    image: chall2,
  },
  {
    name: 'Fendi',
    image: chall3,
  },
  {
    name: 'Ferragamo',
    image: chall4,
  },
  {
    name: 'Gianvito Rossi',
    image: chall5,
  },
  {
    name: 'Givenchy',
    image: chall6,
  },
  {
    name: 'Gucci',
    image: chall7,
  },
  {
    name: 'Herno',
    image: chall8,
  },
  {
    name: 'Hogan',
    image: chall9,
  },
  {
    name: 'Jil Sander',
    image: chall10,
  },
  {
    name: 'Jimmy Choo',
    image: chall11,
  },
  {
    name: 'Kids around',
    image: chall12,
  },
  {
    name: 'Loro Piana',
    image: chall13,
  },
  {
    name: 'Maison Margiela',
    image: chall14,
  },
]

const Home = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [modal, setModal] = useState(false)

  const [tienDat, setTienDat] = useState<number>(100)
  const [biDat, setBiDat] = useState<number>(0)
  const [ky, setKy] = useState<IGame | null>(null)
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const [disableDatCuoc, setDisableDatCuoc] = useState(false)
  const [load, setLoad] = useState(false)
  const { user } = useSelector((state: RootState) => state.authentication)

  const subTienDatHandle = () => {
    if (tienDat > 10000) {
      setTienDat(tienDat - 10000)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current')
      setKy(response.data)
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get('/draws/get-result-ket-qua-ky-truoc')
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKy()
    getKetQuaKyTruoc()
    dispatch(getProfile())
  }, [load])

  const addTienDatHandle = () => {
    setTienDat(tienDat + 10000)
  }

  const money = [100, 500, 1000, 5000, 10000, 30000, 50000, 100000]

  const onChangeTienDatHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (Number.isNaN(Number(value))) return
    setTienDat(Number(value))
  }

  const closeModal = () => {
    setModal(false)
    setBiDat(0)
  }

  const openModal = (biDat: number) => {
    setModal(true)
    setBiDat(biDat)
  }

  const submitHanle = async () => {
    if (disableDatCuoc) {
      ToastErrorBottom('Đã hết thời gian đặt lệnh')
      setModal(false)
      setBiDat(0)
      return
    }

    if (user?.money && user?.money < tienDat) {
      ToastErrorBottom('Số dư không đủ')
      setModal(false)
      setBiDat(0)
      return
    }

    try {
      const response = await axios.post('/history', {
        KyId: ky?.id,
        voted: biDat,
        money: tienDat,
      })
      setModal(false)
      setBiDat(0)
      setTienDat(100)
      ToastSuccessBottom('đặt lệnh thành công')
      dispatch(getProfile())
    } catch (error) {
      setModal(false)
      setBiDat(0)
      // ToastErrorBottom('đặt lệnh thất bại')
      dispatch(getProfile())
      console.error('Error:', error)
    }
  }

  const [box1, setBox1] = useState(2)
  const [box2, setBox2] = useState(1)

  return (
    <Layout>
      <div style={{ minHeight: '100vh', background: '#0e1317' }}>
        <div className="box-custom-0">
          <img className="w-100" src={logotop} />
        </div>

        <div className="d-flex justify-content-center mt-3 box-custom-1">
          <div className="w-100 text-center">
            <img src={phanphoi} />
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3 box-custom-2">
          <div className="w-100 text-center">
            <img src={thoitrang} />
          </div>
          <div className="w-100 text-center">
            <img src={sanpham} />
          </div>
          <div className="w-100 text-center">
            <img src={khachhang} />
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3 box-custom-3">
          <div className="w-100 text-center">
            <img src={quanao} />
          </div>
          <div className="w-100 text-center">
            <img src={tuixach} />
          </div>
          <div className="w-100 text-center">
            <img src={giaydep} />
          </div>
          <div className="w-100 text-center">
            <img src={phukien} />
          </div>
          <div className="w-100 text-center">
            <img src={nuochoa} />
          </div>
        </div>

        <KyQuay1
          load={load}
          ky={ky}
          setDisableDatCuoc={setDisableDatCuoc}
          setLoad={setLoad}
        />

        <div className="ketqua box-custom-5 d-flex justify-content-center gap-2 mt-3">
          <div className="item">
            <img src={ketqua} />
            <div>{ketQuaKyTruoc?.ball1 || 0}</div>
          </div>
          <div className="item">
            <img src={ketqua} />
            <div>{ketQuaKyTruoc?.ball2 || 0}</div>
          </div>
          <div className="item">
            <img src={ketqua} />
            <div>{ketQuaKyTruoc?.ball3 || 0}</div>
          </div>
          <div className="item">
            <img src={ketqua} />
            <div>{ketQuaKyTruoc?.ball4 || 0}</div>
          </div>
          <div className="item">
            <img src={ketqua} />
            <div>{ketQuaKyTruoc?.ball5 || 0}</div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3 box-custom-4 align-items-center">
          <div className="d-flex justify-content-start">
            <div className="item-left w-100 " onClick={() => openModal(1)}>
              <img src={nleft} />
              <div
                className="fw-bold"
                style={{ fontSize: 25, marginTop: 3, color: '#000' }}
              >
                NHẬP
              </div>
            </div>
          </div>
          <div
            style={{ width: 150, flexGrow: 0, flexShrink: 0 }}
            className="text-center"
          >
              <h4 className="fw-bold m-0 gradient-text">#Prudential</h4>
            </div>
          <div className="d-flex justify-content-end">
            <div className="item-right w-100 " onClick={() => openModal(2)}>
              <img style={{ transform: 'rotate(180deg)' }} src={nleft} />
              <div
                className="fw-bold"
                style={{ fontSize: 25, marginTop: 3, color: '#000' }}
              >
                XUẤT
              </div>
            </div>
          </div>
        </div>

        <div className="notegame mt-4 px-3">
          <p className="mb-0" style={{ fontSize: 13 }}>
            Tri ân khách hàng nhân dịp kỷ niệm 10 năm thành lập Prudentiall Store. Phần quà lên đến 1.000.000.000 VND !
          </p>
        </div>

        <Marquee>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage1}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage2}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage3}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage4}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage5}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage6}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage7}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage8}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage9}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage10}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage11}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage12}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage13}
            />
          </div>
          <div>
            <img
              style={{ width: 60, height: 75, objectFit: 'cover' }}
              src={challimage14}
            />
          </div>
        </Marquee>

        <div>
          <img className='w-100' src={img123} />
        </div>

        <div className="list-iii2" style={{ background: '#d2d2d2' }}>
          <Marquee direction="right">
            {dataii2?.map((item, index) => {
              return (
                <div className="item p-2 me-3">
                  <img
                    style={{ width: 60, height: 75, objectFit: 'cover' }}
                    src={item.image}
                  />
                  <div
                    style={{ fontSize: 12, fontStyle: 'italic' }}
                    className="text-center mt-2"
                  >
                    {item.name}
                  </div>
                </div>
              )
            })}
          </Marquee>
        </div>

        {/* <div className="item-history d-flex justify-content-between align-items-center">
            <div style={{ fontSize: 16 }} className="fw-bold">
              <img className="logo-top" src={gif1} width={100} />
            </div>
            <div className="fw-bold">
              Mã sản phẩm hiện tại {(ky?.id ?? 0) - 1}
            </div>
            <div>
              <NavLink to="/info">
                <InfoIcon
                  color="#fff"
                  fill="#113b49"
                  style={{ borderRadius: '50%', background: '#fff' }}
                />
              </NavLink>
            </div>
          </div>
  
          <div className="box-result d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div className="list-ball d-flex justify-content-between gap-2">
                <div className="ball">
                  <span>{ketQuaKyTruoc?.ball1 || 0}</span>
                </div>
                <div className="ball">
                  <span>{ketQuaKyTruoc?.ball2 || 0}</span>
                </div>
                <div className="ball">
                  <span>{ketQuaKyTruoc?.ball3 || 0}</span>
                </div>
                <div className="ball">
                  <span>{ketQuaKyTruoc?.ball4 || 0}</span>
                </div>
                <div className="ball">
                  <span>{ketQuaKyTruoc?.ball5 || 0}</span>
                </div>
              </div>
            </div>
          </div>
  
          <KyQuay
            load={load}
            ky={ky}
            setDisableDatCuoc={setDisableDatCuoc}
            setLoad={setLoad}
          />
  
          <div
            style={{ backgroundColor: '#113b49', height: 67 }}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="fw-bold d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: 'rgb(255, 99, 44)',
                color: '#fff',
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 3,
                borderColor: '#fff',
                borderWidth: 2,
                borderStyle: 'solid',
              }}
            >
              Bảng phân phối công việc
            </div>
          </div>
  
          <div className="d-flex justify-content-between px-3 py-2 box-1">
            <div
              onClick={() => setBox1(1)}
              className={box1 === 1 ? 'active' : ''}
            >
              THỜI TRANG
            </div>
            <div
              onClick={() => setBox1(2)}
              className={box1 === 2 ? 'active' : ''}
            >
              SẢN PHẨM
            </div>
            <div
              onClick={() => setBox1(3)}
              className={box1 === 3 ? 'active' : ''}
            >
              KHÁCH HÀNG
            </div>
          </div>
  
          <div className="d-flex justify-content-between px-3 py-2 box-2">
            <div
              onClick={() => setBox2(1)}
              className={box2 === 1 ? 'active' : ''}
            >
              Quần áo
            </div>
            <div
              onClick={() => setBox2(2)}
              className={box2 === 2 ? 'active' : ''}
            >
              Túi xách
            </div>
            <div
              onClick={() => setBox2(3)}
              className={box2 === 3 ? 'active' : ''}
            >
              Giày dép
            </div>
            <div
              onClick={() => setBox2(4)}
              className={box2 === 4 ? 'active' : ''}
            >
              Phụ kiện
            </div>
            <div
              onClick={() => setBox2(5)}
              className={box2 === 5 ? 'active' : ''}
            >
              Nước hoa
            </div>
          </div>
  
          <div className="box-datcuoc mt-3">
            <div>
              <div className="d-flex gap-2">
                <button
                  style={{ background: 'rgb(154, 195, 223)', height: 55 }}
                  className={
                    biDat === 1
                      ? 'active justify-content-center'
                      : 'justify-content-center'
                  }
                  onClick={() => openModal(1)}
                >
                  <span
                    className="fw-bold"
                    style={{ marginTop: 5, fontSize: 18 }}
                  >
                    NHẬP
                  </span>
                </button>
                <button
                  style={{ background: '#83ba47', height: 55 }}
                  className={
                    biDat === 2
                      ? 'active justify-content-center'
                      : 'justify-content-center'
                  }
                  onClick={() => openModal(2)}
                >
                  <span
                    className="fw-bold"
                    style={{ marginTop: 5, fontSize: 18 }}
                  >
                    XUẤT
                  </span>
                </button>
              </div>
            </div>
          </div>
  
          <div className="box-image-1 p-3">
            <CRow>
              <CCol xs={4}>
                <div className="item">
                  <img src={img1} />
                  <h6 className="fw-bold mt-2">Quần áo</h6>
                </div>
              </CCol>
  
              <CCol xs={4}>
                <div className="item">
                  <img src={img2} />
                  <h6 className="fw-bold mt-2">Túi xách</h6>
                </div>
              </CCol>
  
              <CCol xs={4}>
                <div className="item">
                  <img src={img3} />
                  <h6 className="fw-bold mt-2">Giày dép</h6>
                </div>
              </CCol>
  
              <CCol xs={4}>
                <div className="item">
                  <img src={img4} />
                  <h6 className="fw-bold mt-2">Phụ kiện</h6>
                </div>
              </CCol>
  
              <CCol xs={4}>
                <div className="item">
                  <img src={img5} />
                  <h6 className="fw-bold mt-2">Thắt lưng</h6>
                </div>
              </CCol>
  
              <CCol xs={4}>
                <div className="item">
                  <img src={img6} />
                  <h6 className="fw-bold mt-2">Nước hoa</h6>
                </div>
              </CCol>
            </CRow>
          </div>
  
          <div className="p-3">
            <img src={img7} className="w-100" />
          </div>
  
          <div className="notegame">
            <p className="mb-0">
              TRI ÂN KHÁCH HÀNG NHÂN DỊP KỶ NIỆM 10 NĂM THÀNH LẬP
              DOANH NGHIỆP CÔNG NGHỆ AUTOSALE PRUDENTIALL . PHẦN QUÀ LÊN ĐẾN
              1.000.000 USD !
            </p>
          </div> */}
      </div>

      <SModal
        alignment="center"
        visible={modal}
        backdrop="static"
        className="custom-modal custom-modal-datcuoc"
      >
        {/* <CModalHeader>dsadas</CModalHeader> */}

        <div className='logo-bg'>
            <img className='w-100' src={logoBG} />
        </div>
        <CModalBody>
          <div>
            <div className="text-center mb-3" style={{ color: "#f1c232"}}>Số lượng</div>

            <div className="form-input d-flex gap-3 justify-content-center align-items-center">
              <div className="sub" onClick={subTienDatHandle} style={{color: "#f1c232"}}>
                -
              </div>
              <CFormInput
                onChange={(e) => onChangeTienDatHandle(e)}
                className="text-center fw-normal"
                type="text"
                value={tienDat}
                style={{ color: "#f1c232", background: "transparent", borderColor: "#f1c232", borderWidth: 2 }}
              />
              <div className="add" onClick={addTienDatHandle} style={{color: "#f1c232"}}>
                +
              </div>
            </div>

            <CRow className="box-list-fill mt-4 g-3">
              {money.map((item, index) => {
                return (
                  <CCol xs={3} key={index}>
                    <div
                      className={
                        'fill mb-2' + (item === tienDat ? ' active' : '')
                      }
                      onClick={() => setTienDat(item)}
                      style={{ color: "#f1c232", background: "transparent", borderColor: "#f1c232", borderWidth: 2, fontSize: 12 }}
                    >
                      <span>
                        {new Intl.NumberFormat('vi-VN').format(item || 0)}
                      </span>
                    </div>
                  </CCol>
                )
              })}
            </CRow>
          </div>
        </CModalBody>
        <CModalFooter  className="d-flex justify-content-around border-0" 
        style={{ background: "#113b49", borderRadius: 20  }}>
          <div
            className="cancel fw-bold"
            onClick={closeModal}
            style={{
              background: 'rgb(144 144 144)',
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 25,
              paddingRight: 25,
              borderRadius: 25,
              color: "#f1c232",
              borderColor: "#fff", 
              borderWidth: 2,
              borderStyle: "solid"
            }}
          >
            Hủy bỏ
          </div>
          <div
            className="submit fw-bold"
            onClick={submitHanle}
            style={{
              background: '#113b49',
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 25,
              paddingRight: 25,
              borderRadius: 25,
              color: "#f1c232",
              borderColor: "#f1c232", 
              borderWidth: 2,
              borderStyle: "solid"
            }}

          >
            Xác nhận
          </div>
        </CModalFooter>
      </SModal>
    </Layout>
  )
}

export default Home
