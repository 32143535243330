import { combineReducers } from 'redux';
import container from './components/containers/reducer';
import messageReducer from './components/modules/Chat/Message/message.reducer';
import chatReducer from './components/modules/Chat/chat.reducer';
import permissionReducer from './components/modules/SystemSetting/PermissionGroup/permission.reducer';
import usersReducer from './components/modules/UserManagement/usersManagement.reducer';
import authentication from './components/modules/auth/auth.reducer';
import gameReducer from './components/modules/GameManagement/gameManagement.reducer';
import historyReducer from './components/modules/HistoryManagement/historyManagement.reducer';
import transactionReducer from './components/modules/TransactionManagement/transactionManagement.reducer';
import rechargeReducer from './components/modules/RechargeManagement/rechargeManagement.reducer';

const rootReducer = combineReducers({
  container,
  authentication,
  usersReducer,
  permissionReducer,
  messageReducer,
  chatReducer,
  gameReducer,
  historyReducer,
  transactionReducer,
  rechargeReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
